var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "justify-start",
                    attrs: {
                      disabled: _vm.selected.length != 1,
                      text: "",
                      block: "",
                      color: "secondary",
                    },
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-table-edit"),
                  ]),
                  _vm._v(" edit description "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.editDialog,
        callback: function ($$v) {
          _vm.editDialog = $$v
        },
        expression: "editDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center title secondary--text" },
              [
                _c("v-icon", { attrs: { left: "" } }, [
                  _vm._v("mdi-table-edit"),
                ]),
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.objectType) + " edit description"),
                ]),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "mt-1" },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Old " + _vm._s(_vm.objectType) + " description:"),
              ]),
              _c("p", [_vm._v(_vm._s(_vm.oldName))]),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("New " + _vm._s(_vm.objectType) + " description:"),
              ]),
              _c("v-text-field", {
                attrs: { outlined: "" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _vm._v(
                          "Provide a new " +
                            _vm._s(_vm.objectType) +
                            " description"
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.newObjectName,
                  callback: function ($$v) {
                    _vm.newObjectName = $$v
                  },
                  expression: "newObjectName",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    disabled: _vm.editing,
                    text: "",
                    color: "secondary",
                  },
                  on: {
                    click: function ($event) {
                      _vm.editDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.editing, text: "", color: "error" },
                  on: { click: _vm.edit },
                },
                [_vm._v("confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }